<template>
  <div id="riskPlan">
    <el-dialog
      :title="riskPlanFormTitle"
      width="680px"
      :visible.sync="riskPlanDialogVisible"
      :close-on-click-modal="false"
      @close="riskPlanDialogClose"
    >
      <el-form
        ref="riskPlanFormRef"
        :model="riskPlanForm"
        :rules="riskPlanFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="文件编号" prop="fileNo">
              <el-input v-model="riskPlanForm.fileNo" placeholder="请输入文件编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计划名称" prop="planName">
              <el-input v-model="riskPlanForm.planName" placeholder="请输入计划名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="riskPlanForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品型号" prop="spec">
              <el-input v-model="riskPlanForm.spec" placeholder="请输入产品型号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制人" prop="editor">
              <el-input v-model="riskPlanForm.editor" placeholder="请输入编制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制日期" prop="editDate">
              <el-date-picker v-model="riskPlanForm.editDate" placeholder="请选择编制日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="riskPlanForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核日期" prop="reviewDate">
              <el-date-picker v-model="riskPlanForm.reviewDate" placeholder="请选择审核日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="riskPlanForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approvedDate">
              <el-date-picker v-model="riskPlanForm.approvedDate" placeholder="请选择批准日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="文件上传">
              <el-scrollbar style="height: 100px">
                <el-upload
                  ref="uploadRef"
                  action=""
                  :http-request="handleHttp"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  multiple
                >
                  <el-button size="small" type="primary">
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="riskPlanDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="riskPlanFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="计划名称">
        <el-input v-model="searchForm.planName" placeholder="请输入计划名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="riskPlanPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="fileNo" label="文件编号" />
      <el-table-column prop="planName" label="计划名称" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="产品型号" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="编制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editDate">{{ scope.row.editDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="审核日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="编制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvedDate">{{ scope.row.approvedDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="riskPlanPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addRiskPlan, deleteRiskPlan, updateRiskPlan, selectRiskPlanInfo, selectRiskPlanList } from '@/api/technology/riskPlan'
import { upload } from '@/api/main/file'

export default {
  data () {
    return {
      riskPlanDialogVisible: false,
      riskPlanFormTitle: '',
      riskPlanForm: {
        id: '',
        fileNo: '',
        planName: '',
        productName: '',
        spec: '',
        editor: '',
        editDate: '',
        reviewer: '',
        reviewDate: '',
        approver: '',
        approvedDate: '',
        planUrl: '',
        planUrlName: ''
      },
      riskPlanFormRules: {
        fileNo: [{ required: true, message: '文件编号不能为空', trigger: ['blur', 'change']}]
      },
      riskPlanPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        planName: ''
      },
      fileList: []
    }
  },
  created () {
    selectRiskPlanList(this.searchForm).then(res => {
      this.riskPlanPage = res
    })
  },
  methods: {
    riskPlanDialogClose () {
      this.$refs.riskPlanFormRef.resetFields()
      this.$refs.uploadRef.clearFiles()
      this.fileList = []
    },
    riskPlanFormSubmit () {
      if (this.riskPlanFormTitle === '风险管理计划详情') {
        this.riskPlanDialogVisible = false
        return
      }
      this.$refs.riskPlanFormRef.validate(async valid => {
        if (valid) {
          this.riskPlanForm.planUrl = this.fileList.map(file => file.path).join(',')
          this.riskPlanForm.planUrlName = this.fileList.map(file => file.name).join(',')
          if (this.riskPlanFormTitle === '新增风险管理计划') {
            await addRiskPlan(this.riskPlanForm)
          } else if (this.riskPlanFormTitle === '修改风险管理计划') {
            await updateRiskPlan(this.riskPlanForm)
          }
          this.riskPlanPage = await selectRiskPlanList(this.searchForm)
          this.riskPlanDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.riskPlanFormTitle = '新增风险管理计划'
      this.riskPlanDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteRiskPlan(row.id)
        if (this.riskPlanPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.riskPlanPage = await selectRiskPlanList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.riskPlanFormTitle = '修改风险管理计划'
      this.riskPlanDialogVisible = true
      this.selectRiskPlanInfoById(row.id)
    },
    handleInfo (index, row) {
      this.riskPlanFormTitle = '风险管理计划详情'
      this.riskPlanDialogVisible = true
      this.selectRiskPlanInfoById(row.id)
    },
    selectRiskPlanInfoById (id) {
      selectRiskPlanInfo(id).then(res => {
        this.riskPlanForm.id = res.id
        this.riskPlanForm.fileNo = res.fileNo
        this.riskPlanForm.planName = res.planName
        this.riskPlanForm.productName = res.productName
        this.riskPlanForm.spec = res.spec
        this.riskPlanForm.editor = res.editor
        this.riskPlanForm.editDate = res.editDate
        this.riskPlanForm.reviewer = res.reviewer
        this.riskPlanForm.reviewDate = res.reviewDate
        this.riskPlanForm.approver = res.approver
        this.riskPlanForm.approvedDate = res.approvedDate
        if (res.planUrl) {
          const planUrl = res.planUrl.split(',')
          const planUrlName = res.planUrlName.split(',')
          this.fileList = []
          for (let i = 0; i < planUrl.length; i++) {
            this.fileList.push({
              name: planUrlName[i],
              path: planUrl[i]
            })
          }
        }
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectRiskPlanList(this.searchForm).then(res => {
        this.riskPlanPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectRiskPlanList(this.searchForm).then(res => {
        this.riskPlanPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectRiskPlanList(this.searchForm).then(res => {
        this.riskPlanPage = res
      })
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileList.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    }
  }
}
</script>

<style>
#riskPlan .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>

import axios from '@/common/axios'
import qs from 'qs'

export function addRiskPlan (data) {
  return axios({
    method: 'post',
    url: '/technology/riskPlan/add',
    data: qs.stringify(data)
  })
}

export function deleteRiskPlan (id) {
  return axios({
    method: 'delete',
    url: '/technology/riskPlan/delete/' + id
  })
}

export function updateRiskPlan (data) {
  return axios({
    method: 'put',
    url: '/technology/riskPlan/update',
    data: qs.stringify(data)
  })
}

export function selectRiskPlanInfo (id) {
  return axios({
    method: 'get',
    url: '/technology/riskPlan/info/' + id
  })
}

export function selectRiskPlanList (query) {
  return axios({
    method: 'get',
    url: '/technology/riskPlan/list',
    params: query
  })
}
